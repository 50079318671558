import React from 'react';
import Head from 'next/head';
import { Button } from 'semantic-ui-react';
import { StandardSeo } from 'components/Universal/StandardSeo';
import { defaultSEO } from 'components/Universal/SeoConfig';
import { FBPixel } from 'components/Universal/FBPixel';

function Custom404(props) {
  return (
    <>
      <FBPixel/>
      <StandardSeo 
        titleProp={defaultSEO.title}
        descriptionProp={defaultSEO.description}
        keywordProp={defaultSEO.keywords}
      />
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
        <title>Catalist - Page not found</title>
      </Head>
      <div>
        <div className="NotFound__image" />
        <div className="NotFound__Container__button">
          <div>
            <Button href="/" color="blue">
              Back to home page
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default (Custom404);
